import React from 'react'

function SvgRtkThemeApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#252525'
        d='M3200 8960h640c176 0 320-144 320-320v-640h1920v640c0 176 144 320 320 320h640c176 0 320 144 320 320v320H2880v-320c0-176 144-320 320-320zM1280 640h7266c-302 213-606 423-905 640H1280v5760h7680V2314c214-301 424-603 640-902v5628c0 352-288 640-640 640H1280c-353 0-640-287-640-640V1280c0-353 287-640 640-640z'
      />
      <path
        fill='#3E9BD5'
        d='M5064 5084c677 748 1 1636-901 1636H2602c468-172 596-488 706-826 89-276 165-567 402-810 315-321 933-464 1354 0z'
      />
      <path
        fill='#252525'
        d='M9407 772c-18-18-36-35-54-53-89-88-226-99-328-26-1229 877-1995 1341-3365 2695-180 178-370 375-534 588 447 190 812 568 1013 1007 216-161 415-350 598-530 1369-1355 1809-2141 2696-3356 74-101 63-237-26-325zM4941 4246c-55 93-104 189-143 288 354 123 643 425 781 771 99-39 195-87 289-141-170-408-511-761-927-918z'
      />
    </svg>
  )
}

export default SvgRtkThemeApp
