import React from 'react'

function SvgMedal(props) {
  return (
    <svg viewBox='0 0 405.075 405.075' {...props}>
      <path d='M373.488 337.075l-59.2-104c6-2.8 9.6-9.2 9.2-16.4l-4.4-36.8 25.2-26.4c5.6-5.6 6-15.2.4-22l-25.2-27.2 5.2-37.2c.8-8-4.8-16-12.8-17.6l-36.4-7.2-17.6-32.4c-3.6-7.6-12.4-10.8-20.4-7.6h-.4l-33.6 15.6-32.8-16c-3.6-2-8-2.4-12-1.2s-7.2 4-9.2 7.6l-18 32.4-36.4 6.4c-8.4 1.6-14 9.2-13.2 18l4.4 36.8-25.2 26.4c-5.6 5.6-6 15.2-.4 22l25.2 27.2-5.2 37.2c-.8 7.2 3.6 14 10.4 16.8l-59.6 105.6c-1.6 2.4-1.2 5.6 0 8 1.6 2.4 4 4 6.8 4h64l29.2 50.8c1.6 2.4 4 4 6.8 4s5.6-1.6 6.8-4l57.2-97.6 57.2 98.8c1.6 2.4 4 4 6.8 4s5.6-1.6 6.8-4l29.2-52h64c2.8 0 5.6-1.6 6.8-4 2-2.4 2-5.6.4-8zm-234.8 42.8l-24.8-42.8c-1.6-2.4-4-4-6.8-4h-55.2l55.2-97.6 22.8 4 17.6 32.4c3.6 7.6 12.4 10.8 20.4 7.6h.4l16.4-7.6 8 14-54 94zm23.2-114.8h-.4l-17.6-33.2c-2.4-4-6.4-7.2-11.2-7.6l-36.8-6.8 5.2-36.8c.8-4.8-.8-10-4-13.2l-24.8-27.2 25.2-26.4c3.6-3.6 5.2-8.4 4.4-13.2l-4.4-36.4s0-.4.4-.8l36.4-6.4c4.4-.8 8.8-3.6 11.2-8l18-32.4 32.8 16c4.4 2.4 10 2.4 14.4 0l33.2-15.2h.4l17.6 33.2c2.4 4 6.4 7.2 10.8 7.6l36.4 7.2-5.2 36.8c-.8 4.8.8 10 4 13.2l25.2 27.2-25.6 26.4c-3.6 3.6-5.2 8.4-4.4 13.2l4.4 36.8-36 6c-4.4.8-8.8 3.6-11.2 8l-18 32.4-32.8-16c-4.4-2.4-10-2.4-14.4 0l-33.2 15.6zm136 68c-2.8 0-5.6 1.6-6.8 4l-24.8 44-56.4-97.6c-.4-1.2-.8-2-1.6-2.8l-8.8-15.6 2.8-1.2 32.8 16c2.4 1.2 4.8 1.6 7.2 1.6 5.6 0 11.2-2.8 14-8l18-32.4 24-4.4 54.8 96.4h-55.2z' />
      <path d='M282.288 141.075c0-44-36-80-80-80s-80 36-80 80 36 80 80 80 80-36 80-80zm-80 64c-35.2 0-64-28.8-64-64s28.8-64 64-64 64 28.8 64 64-28.8 64-64 64z' />
    </svg>
  )
}

export default SvgMedal
