import React from 'react'

function SvgRtkMarketAnalyticsApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#252525'
        d='M4800 7013v2575C2475 9424 640 7487 640 5120c0-1639 880-3072 2193-3853l1153 2304c-476 349-786 913-786 1549 0 951 692 1741 1600 1893zM7013 5440h2575c-156 2219-1929 3992-4148 4149V7013c804-135 1438-769 1573-1573z'
      />
      <path
        fill='#3E9BD5'
        d='M5120 640c2367 0 4304 1835 4468 4160H7013c-152-908-942-1600-1893-1600-195 0-384 29-562 84L3405 980c528-219 1107-340 1715-340z'
      />
    </svg>
  )
}

export default SvgRtkMarketAnalyticsApp
