import React from "react";

import { makeStyles} from '@material-ui/core/styles'
import {List, ListItem, Box} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: '#2E2E2E',
      color: "#ffffff",
    },
    title: {
        color: "#17a4e0",
    },
    spacer: {
        flexGrow: 4
    },
    right: {
        textAlign: 'right',
        padding: '12px',
    },
    rightItem: {
        justifyContent: 'flex-end',
    },
}))  

export default function Footer() {
    const classes = useStyles();

    return (
      <Paper elevation={0} square className={classes.root}>
        <Box>
        <List>
            <ListItem className={classes.title}>PRODUCT</ListItem>
            <ListItem>Extensibility</ListItem>
            <ListItem>Orchestration</ListItem>
            <ListItem>Security</ListItem>
            <ListItem>Performance</ListItem>
            <ListItem>Pricing</ListItem>
            <ListItem>Compare</ListItem>
        </List>
        </Box>
        <Box>
        <List>
            <ListItem className={classes.title}>INTEGRATIONS</ListItem>
            <ListItem>Sources</ListItem>
            <ListItem>Destinations</ListItem>
            <ListItem>Analysis Tools</ListItem>
        </List>
        </Box>
        <Box>
        <List>
            <ListItem className={classes.title}>LEARN</ListItem>
            <ListItem>Documentation</ListItem>
            <ListItem>Community</ListItem>
            <ListItem>Blog</ListItem>
            <ListItem>Testimonials</ListItem>
            <ListItem>GitHub</ListItem>
            <ListItem>Status</ListItem>
        </List>
        </Box>
        <Box>
        <List>
            <ListItem className={classes.title}>COMPANY</ListItem>
            <ListItem>About</ListItem>
            <ListItem>Careers</ListItem>
            <ListItem>Contact</ListItem>
            <ListItem>Customers</ListItem>
            <ListItem>Partners</ListItem>
            <ListItem>Team</ListItem>
        </List>
        </Box>
        <Box>
        <List>
            <ListItem className={classes.title}>SOCIAL</ListItem>
            <ListItem>Twitter</ListItem>
            <ListItem>LinkedIn</ListItem>
            <ListItem>Instagram</ListItem>
        </List>
        </Box>
        <Box className={classes.spacer} />
        <Box className={classes.right}>
            <ListItem className={classes.rightItem}><img src='../Rhetorik-New-Logo-White-01-1024x278.png' height='30px' alt='Rhetorik logo' /></ListItem>
            <ListItem className={classes.rightItem}>(c) 2020 Rhetorik Limited</ListItem>
            <ListItem className={classes.rightItem}>Terms of Use</ListItem>
            <ListItem className={classes.rightItem}>Privacy Policy</ListItem>
            <ListItem className={classes.rightItem}><img src='../dmaLogoGrey.png' height='50px' alt='DMA Member logo' /></ListItem>
        </Box>
      </Paper>
    );
}