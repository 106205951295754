import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { XAxis, YAxis, ResponsiveContainer, BarChart, Bar } from 'recharts';
import Title from './Title';

interface ChartProps {
    title: string
    barColor: string
  }

// Generate Sales Data
function createData(jobFunction: string, time: string, amount: number) {
  return { jobFunction, time, amount };
}

const data = [
  createData('EDP', '00:00', 7000),
  createData('SYS', '03:00', 5800),
  createData('TEL', '06:00', 5000),
  createData('SEC', '09:00', 4000),
  createData('SEC', '12:00', 3500),
  createData('CIO', '15:00', 2000),
  createData('SAM', '18:00', 1500),
  createData('OPS', '21:00', 1000),
  createData('LAN', '24:00', 800),
  createData('FIN', '24:00', 250),
];

export default function Chart(props: ChartProps) {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <ResponsiveContainer minHeight="296px">
        <BarChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="jobFunction" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary} />
          <Bar dataKey="amount"  fill={props.barColor}  />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}