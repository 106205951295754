import React from 'react'

function SvgRtkUsersApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#3E9BD5'
        d='M9600 6456c0 648-1536 904-2560 904-384 0-841-36-1261-115-42-74-90-145-144-214-220-281-520-497-807-706 379-400 612-939 612-1533 0-107-8-211-22-314 74-56 153-113 236-174 381 300 863 479 1386 479s1005-179 1386-479c765 562 1174 831 1174 2152z'
      />
      <path
        fill='#3E9BD5'
        d='M7040 640c1060 0 1920 856 1920 1912s-860 1912-1920 1912-1920-856-1920-1912S5980 640 7040 640z'
      />
      <path
        fill='#252525'
        d='M5760 8696c0 648-1536 904-2560 904S640 9344 640 8696c0-1319 410-1591 1174-2152 381 300 863 479 1386 479s1005-179 1386-479c765 562 1174 831 1174 2152z'
      />
      <path
        fill='#252525'
        d='M3200 2880c1060 0 1920 856 1920 1912s-860 1912-1920 1912-1920-856-1920-1912 860-1912 1920-1912z'
      />
    </svg>
  )
}

export default SvgRtkUsersApp
