import React from 'react'

function SvgRtkSavedSearchesApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#252525'
        d='M1920 3039c0-1325 1075-2399 2400-2399 777 0 1492 375 1937 982 100-15 201-22 303-22 1149 0 2080 931 2080 2080v32c572 292 960 888 960 1568 0 972-788 1760-1760 1760h-213c-47-133-123-254-228-359l-281-281h722c619 0 1120-501 1120-1120 0-593-460-1078-1043-1117 54-151 83-314 83-483 0-795-645-1440-1440-1440-218 0-425 49-610 135-262-642-893-1095-1630-1095-972 0-1760 788-1760 1760 0 168 23 330 67 483-752 48-1347 673-1347 1437 0 795 645 1440 1440 1440h402l-281 281c-104 105-180 225-227 356-1099-55-1974-964-1974-2077 0-861 525-1606 1280-1921z'
      />
      <path
        fill='#3E9BD5'
        d='M7173 6907L5346 5081c-124-124-328-124-452 0L3067 6907c-124 125-187 277-187 453v1159c0 142 172 214 273 113l1327-1327v2135c0 88 72 160 160 160h960c88 0 160-72 160-160V7305l1327 1327c101 101 273 29 273-113V7360c0-176-63-328-187-453z'
      />
    </svg>
  )
}

export default SvgRtkSavedSearchesApp
