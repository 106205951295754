import React from 'react'

function SvgRtkDownloadApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#252525'
        d='M1920 3039c0-1325 1075-2399 2400-2399 777 0 1492 375 1937 982 100-15 201-22 303-22 1149 0 2080 931 2080 2080v32c572 292 960 888 960 1568 0 972-788 1760-1760 1760h-160v-640h160c619 0 1120-501 1120-1120 0-593-460-1078-1043-1117 54-151 83-314 83-483 0-795-645-1440-1440-1440-218 0-425 49-610 135-262-642-893-1095-1630-1095-972 0-1760 788-1760 1760 0 168 23 330 67 483-752 48-1347 673-1347 1437 0 741 560 1351 1280 1431v643c-1074-82-1920-979-1920-2074 0-861 525-1606 1280-1921zm4403 3361h-243v242l243-242zm-2163 0h-243l243 242v-242z'
      />
      <path
        fill='#3E9BD5'
        d='M7173 7813L5346 9639c-124 124-328 124-452 0L3067 7813c-124-125-187-277-187-453V6201c0-142 172-214 273-113l1327 1327V5280c0-88 72-160 160-160h960c88 0 160 72 160 160v2135l1327-1327c101-101 273-29 273 113v1159c0 176-63 328-187 453z'
      />
    </svg>
  )
}

export default SvgRtkDownloadApp
