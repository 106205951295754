import React from 'react'

function SvgRtkHelpApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#3E9BD5'
        d='M2888 3986c42-460 246-874 555-1183 348-347 828-563 1357-563h640c529 0 1009 216 1357 563 347 348 563 828 563 1357 0 395-117 765-329 1078-210 310-510 556-878 703l-393 157v142c0 44-16 82-47 113s-69 47-113 47h-960c-88 0-160-72-160-160v-140c0-261 79-508 221-718 140-207 340-371 584-469l393-157c128-51 230-133 298-233 67-99 104-223 104-363 0-176-72-336-188-452s-276-188-452-188h-640c-176 0-336 72-452 188-87 87-149 199-175 324-15 75-80 128-157 128h-969c-46 0-86-18-118-52-31-34-45-76-41-122zm1752 2734h960c88 0 160 72 160 160v960c0 88-72 160-160 160h-960c-88 0-160-72-160-160v-960c0-88 72-160 160-160z'
      />
      <path
        fill='#252525'
        d='M5120 640c2474 0 4480 2006 4480 4480S7594 9600 5120 9600 640 7594 640 5120 2646 640 5120 640zm0 640c-2121 0-3840 1719-3840 3840s1719 3840 3840 3840 3840-1719 3840-3840-1719-3840-3840-3840z'
      />
    </svg>
  )
}

export default SvgRtkHelpApp
