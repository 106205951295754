//import axios from 'axios'
import React from 'react';
import _ from 'lodash'

/* Mock Data */
import mockCompanies from '../data/companies.json'
import mockContacts from '../data/contacts.json'

/* Material-ui */
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import CardHeader  from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, withStyles, Typography, createStyles, Theme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
// import WorkIcon from '@material-ui/icons/Work';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';

/* Components */
import Title from './Title'

interface CompanyType {
  id: string;
  name: string;
  url: string;
  products: string;
}

interface ContactType {
  id: string;
  name: string;
  last_name: string;
  first_name: string;
  job_title: JobTitleType;
  product: ProductType;
  vendor: VendorType;
}

interface JobTitleType {
  id: string;
  clean_job_title: {id: string; title: string;}
  job_title: string;
}

interface ProductType {
  id: string;
  name: string;
}

interface VendorType {
  id: string;
  name: string;
}


interface CountryType {
    city: string;
    country_code: string;
}

 interface Styles extends Partial<Record<SwitchClassKey, string>> {
     focusVisible?: string;
   }
  
  interface Props extends SwitchProps {
    classes: Styles;
  }

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1,1,1,0),
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      results: {
        display: "flex",
        flexWrap: "wrap"
      },
      root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            background: "#E4F2F9",
         },
      },
      cardContent: {
          padding: "0",
      },
      borderAvatar: {
          border: "1px solid #E3E3E3",
          width: "30px",
          height: "30px",
      },
      andMore: {
          color: theme.palette.text.disabled,
          fontSize: "11px",
          padding: theme.spacing(1),
      }
  }));

  const FacetButton = withStyles((theme) => ({
    root: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#E0E0E0',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#17A4E0',
        color: '#FFFFFF',
      },
    },
  }))(Button);

  const SearchCard = withStyles((theme) => ({
    root: {
        flex: "1 0 48%",
        margin: theme.spacing(1),
    },
  }))(Card);

  const CriteriaTitle = withStyles((theme) => ({
    root: {
            margin: '22px',
      }
  }))(Title);

  const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function getHighlightedText(text: string, highlight: string) {
    // Split on highlight term and include term into parts, ignore case
    if (text) {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> { parts.map((part, i) => 
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { color: '#17a4e0' } : {} }>
            {part}
        </span>)
    } </span>;
  }
  else
  return text;
}

export default function Asynchronous() {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [options, setOptions] = React.useState<CompanyType[]>([]);
  const [contacts, setContacts] = React.useState<ContactType[]>([]);
  const [jobs, setJobs] = React.useState<JobTitleType[]>([]);
  const [products, setProducts] = React.useState<ProductType[]>([]);
  const [vendors, setVendors] = React.useState<VendorType[]>([]);
  const [query, setQuery] = React.useState<string>('');
  const loading = open && !loaded;

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  React.useEffect(() => {
    let active = true;

    setOptions([]);
    setContacts([]);
    setJobs([]);
    setProducts([]);
    setVendors([]);

    (async () => {
      //const response = await axios.get(`http://localhost:5000/result`);
      //await sleep(1e3); // For demo purposes.
      //const data = await response.data;
      const myCompanies: any = mockCompanies
      const myContacts: any = mockContacts
      const _companies = _.get(myCompanies, 'result.companies').filter((company: CompanyType) => company.name.includes(query))
      const _contacts = _.get(myContacts, 'result.contact').filter((contact: ContactType) => contact.last_name.includes(query))
      const _jobs = _.get(myContacts, 'result.contact').map((c: ContactType) => {return c.job_title}).filter((job: JobTitleType) => job.clean_job_title.title.includes(query))
      const _products = _.get(myContacts, 'result.install').map((c: ContactType) => {return c.product}).filter((product: ProductType) => product.name.includes(query))
      const _vendors = _.get(myContacts, 'result.install').map((c: ContactType) => {return c.vendor}).filter((vendor: VendorType) => vendor.name.includes(query))

      if (active) {
          setOptions(Object.keys(_companies).map((key) => Object.create({id: _companies[key].company_id, name: _companies[key].name, url: _companies[key].url} ) ) as CompanyType[]);
          setContacts(Object.keys(_contacts).map((key) => Object.create({id: _contacts[key].contact_id, name: _contacts[key].first_name + ' ' +_contacts[key].last_name} ) ) as ContactType[]);
          setJobs(Object.keys(_jobs).map((key) => Object.create({id: _jobs[key].clean_job_title.id, job_title: _jobs[key].clean_job_title.title} ) ) as JobTitleType[]);
          setProducts(Object.keys(_products).map((key) => Object.create({id: _products[key].id, name: _products[key].name} ) ) as ProductType[]);
          setVendors(Object.keys(_vendors).map((key) => Object.create({id: _vendors[key].id, name: _vendors[key].name} ) ) as VendorType[]);
          setLoaded(true);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, query]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
      setContacts([]);
      setJobs([]);
      setProducts([]);
      setVendors([]);
      setLoaded(false);
    }
  }, [open]);

  return (
    
    <Card variant="outlined">
      <CardContent>
        <CriteriaTitle>Criteria</CriteriaTitle>
        <TextField
          //label="Search the Rhetorik Universe"
          variant="outlined"
          placeholder="Search the Rhetorik Universe"
          fullWidth
          InputProps={{
            startAdornment: (<SearchIcon color="disabled" />),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </React.Fragment>
            )
          }}
          onChange={e => {
            if (e.target.value) {
              setQuery(e.target.value);
              setOpen(true);
            } else {
              setQuery(e.target.value);
              setOpen(false);
            }
          }}
        />

        {open && loading && <div>Loading...</div>}
        {open && !loading && (
          <Paper className={classes.results}>
            <SearchCard>
              <CardHeader title="Companies" />
              <CardContent classes={{ root: classes.cardContent }}>
                <List>
                  {options.slice(0, 4).map(company => {
                    return (
                      <React.Fragment key={company.id}>
                        <ListItem
                          button
                          selected={selectedIndex === 0}
                          onClick={event => handleListItemClick(event, 0)}
                          className={classes.root}
                        >
                          <ListItemAvatar>
                            <Avatar
                              className={classes.borderAvatar}
                              alt="Auchan"
                              src={`https://logo.clearbit.com/${company.url}?size=25`}
                            />
                          </ListItemAvatar>
                          <ListItemText>
                            {getHighlightedText(company.name, query)}
                          </ListItemText>
                          <ChevronRightIcon />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    );
                  })}
                  <Divider component="li" />
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemText>
                      Company name keyword{" "}
                      {getHighlightedText(`"${query}"`, query)}
                    </ListItemText>
                  </ListItem>
                </List>
              </CardContent>
              <Divider component="div" />
              <Typography className={classes.andMore}>
                ...and {options.length} more
              </Typography>
            </SearchCard>
            <SearchCard>
              <CardHeader title="Geography" />
              <CardContent classes={{ root: classes.cardContent }}>
                <List>
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.borderAvatar}
                        alt="Belgium"
                        src="https://www.countryflags.io/be/flat/64.png"
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      {getHighlightedText("Belgium", query)}
                    </ListItemText>
                    <ChevronRightIcon />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.borderAvatar}
                        alt="France"
                        src="https://www.countryflags.io/fr/shiny/64.png"
                      />
                    </ListItemAvatar>
                    <ListItemText primary="France" />
                    <ChevronRightIcon />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.borderAvatar}
                        alt="Canada"
                        src="https://www.countryflags.io/ca/shiny/64.png"
                      />
                    </ListItemAvatar>
                    <ListItemText primary="Italy" />
                    <ChevronRightIcon />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.borderAvatar}
                        alt="Italy"
                        src="https://www.countryflags.io/it/shiny/64.png"
                      />
                    </ListItemAvatar>
                    <ListItemText primary="Canada" />
                    <ChevronRightIcon />
                  </ListItem>
                </List>
              </CardContent>
              <Divider component="div" />
              <Typography className={classes.andMore}>
                ...and 67 more
              </Typography>
            </SearchCard>
            <SearchCard>
              <CardHeader title="Key People" />
              <CardContent classes={{ root: classes.cardContent }}>
                <List>
                  {contacts.slice(0, 4).map(contact => {
                    return (
                      <React.Fragment key={contact.id}>
                        <ListItem
                          button
                          selected={selectedIndex === 0}
                          onClick={event => handleListItemClick(event, 0)}
                          className={classes.root}
                        >
                          <ListItemAvatar>
                          <Avatar className={classes.borderAvatar}>
                            <AccountCircleRounded />
                          </Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            {getHighlightedText(contact.name, query)}
                          </ListItemText>
                          <ChevronRightIcon />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    );
                  })}
                  <Divider component="li" />
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemText>
                      Contact name keyword{" "}
                      {getHighlightedText(`"${query}"`, query)}
                    </ListItemText>
                  </ListItem>
                </List>
              </CardContent>
              <Divider component="div" />
              <Typography className={classes.andMore}>
                ...and {contacts.length} more
              </Typography>
            </SearchCard>
            <SearchCard>
              <CardHeader title="Vertical Market" />
              <CardContent>
              <List>
                  <ListItem button selected={selectedIndex === 0} onClick={event => handleListItemClick(event, 0)} className={classes.root}>
                    <ListItemText>
                      {getHighlightedText("Services", query)}
                    </ListItemText>
                    <ChevronRightIcon />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem button selected={selectedIndex === 0} onClick={event => handleListItemClick(event, 0)} className={classes.root}>
                    <ListItemText>
                      {getHighlightedText("Government", query)}
                    </ListItemText>
                    <ChevronRightIcon />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem button selected={selectedIndex === 0} onClick={event => handleListItemClick(event, 0)} className={classes.root}>
                    <ListItemText>
                      {getHighlightedText("Food & Beverage industry", query)}
                    </ListItemText>
                    <IOSSwitch defaultChecked />
                  </ListItem>
                  <Divider component="div" />
                    <Typography className={classes.andMore}>
                        ...and 10 more
                    </Typography>
                </List>
              </CardContent>
            </SearchCard>
            <SearchCard>
              <CardHeader title="Job Function" />
              <CardContent classes={{ root: classes.cardContent }}>
                <List>
                  {jobs.slice(0, 4).map(job => {
                    return (
                      <React.Fragment key={job.id}>
                        <ListItem
                          button
                          selected={selectedIndex === 0}
                          onClick={event => handleListItemClick(event, 0)}
                          className={classes.root}
                        >
                          <ListItemAvatar>
                          <Avatar className={classes.borderAvatar}>
                            <AccountCircleRounded />
                          </Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            {getHighlightedText(job.job_title, query)}
                          </ListItemText>
                          <ChevronRightIcon />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    );
                  })}
                  <Divider component="li" />
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemText>
                      Job function keyword{" "}
                      {getHighlightedText(`"${query}"`, query)}
                    </ListItemText>
                  </ListItem>
                </List>
              </CardContent>
              <Divider component="div" />
              <Typography className={classes.andMore}>
                ...and {jobs.length} more
              </Typography>
            </SearchCard>
            <SearchCard>
              <CardHeader title="Products" />
              <CardContent classes={{ root: classes.cardContent }}>
                <List>
                  {products.slice(0, 4).map(product => {
                    return (
                      <React.Fragment key={product.id}>
                        <ListItem
                          button
                          selected={selectedIndex === 0}
                          onClick={event => handleListItemClick(event, 0)}
                          className={classes.root}
                        >
                          <ListItemAvatar>
                          <Avatar className={classes.borderAvatar}>
                            <AccountCircleRounded />
                          </Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            {getHighlightedText(product.name, query)}
                          </ListItemText>
                          <ChevronRightIcon />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    );
                  })}
                  <Divider component="li" />
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemText>
                      Product keyword{" "}
                      {getHighlightedText(`"${query}"`, query)}
                    </ListItemText>
                  </ListItem>
                </List>
              </CardContent>
              <Divider component="div" />
              <Typography className={classes.andMore}>
                ...and {products.length} more
              </Typography>
            </SearchCard>
            <SearchCard>
              <CardHeader title="Vendors" />
              <CardContent classes={{ root: classes.cardContent }}>
                <List>
                  {vendors.slice(0, 4).map(vendor => {
                    return (
                      <React.Fragment key={vendor.id}>
                        <ListItem
                          button
                          selected={selectedIndex === 0}
                          onClick={event => handleListItemClick(event, 0)}
                          className={classes.root}
                        >
                          <ListItemAvatar>
                          <Avatar className={classes.borderAvatar}>
                            <AccountCircleRounded />
                          </Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            {getHighlightedText(vendor.name, query)}
                          </ListItemText>
                          <ChevronRightIcon />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    );
                  })}
                  <Divider component="li" />
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemText>
                      Vendors keyword{" "}
                      {getHighlightedText(`"${query}"`, query)}
                    </ListItemText>
                  </ListItem>
                </List>
              </CardContent>
              <Divider component="div" />
              <Typography className={classes.andMore}>
                ...and {vendors.length} more
              </Typography>
            </SearchCard>
            <SearchCard>
              <CardHeader title="Product and Services Categories" />
              <CardContent classes={{ root: classes.cardContent }}>
                <List>
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={event => handleListItemClick(event, 0)}
                    className={classes.root}
                  >
                    <ListItemText>
                      No result for{" "}
                      {getHighlightedText(`"${query}"`, query)}
                    </ListItemText>
                  </ListItem>
                </List>
              </CardContent>
            </SearchCard>

          </Paper>
        )}
        {/* <Typography variant="h5" gutterBottom>
                  Criteria
              </Typography> */}
        {/* <Autocomplete
      id="asynchronous-demo"
      style={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      size="small"
      renderInput={(params) => (
          
        <TextField
          {...params}
          label="Asynchronous"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            // startAdornment: (
            //     <SearchIcon color="disabled" />
            // ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
      )}

      renderOption={(option) => {
        const matches = option.name;
        // const parts = parse(
        //   option.structured_formatting.main_text,
        //   matches.map((match: any) => [match.offset, match.offset + match.length]),
        // );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <SearchCard>
                <CardContent>
                  <Title>Companies</Title>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel htmlFor="filled-age-native-simple">
                      Age
                    </InputLabel>
                    <Select
                      native
                      value={state.age}
                      onChange={handleChange}
                      inputProps={{
                        name: "age",
                        id: "filled-age-native-simple"
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirty</option>
                    </Select>
                  </FormControl>
                </CardContent>
              </SearchCard>
            </Grid>
            <Grid item>
              <SearchCard>
                <CardContent>
                  <Title>Key People</Title>2
                </CardContent>
              </SearchCard>
            </Grid>
            <Grid item>
              <SearchCard>
                <CardContent>
                  <Title>Vertical Market</Title>
                </CardContent>
              </SearchCard>
            </Grid>
            <Grid item>
              <SearchCard>
                <CardContent>
                  <Title>Job Function</Title>
                </CardContent>
              </SearchCard>
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                {matches}
              </Typography>
            </Grid>
          </Grid>
        );
      }}



    /> */}

        <FacetButton
          variant="contained"
          className={classes.margin}
          disableElevation
        >
          Vertical Market
        </FacetButton>
        <FacetButton
          variant="contained"
          className={classes.margin}
          disableElevation
        >
          Job Function
        </FacetButton>
        <FacetButton
          variant="contained"
          className={classes.margin}
          disableElevation
        >
          Geography
        </FacetButton>
        <FacetButton
          variant="contained"
          className={classes.margin}
          disableElevation
        >
          Products and Services
        </FacetButton>
        <FacetButton
          variant="contained"
          className={classes.margin}
          disableElevation
        >
          Metadata
        </FacetButton>
        <FacetButton
          variant="contained"
          className={classes.margin}
          disableElevation
        >
          More Filters
        </FacetButton>
      </CardContent>
    </Card>
  );
}