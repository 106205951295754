import React from 'react';
import { Auth } from 'aws-amplify'

import { makeStyles, Theme, withStyles,} from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MessagesIcon from '@material-ui/icons/ModeCommentOutlined'
import SvgIcon from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import Chart from '../sharedComponents/Chart';
import Footer from '../sharedComponents/Footer';
import Omnibox from '../sharedComponents/Omnibox';

import RTKSearchAppIcon from '../icons/RtkSearchApp.js'
import RTKSavedSearchIcon from '../icons/RtkSavedSearchesApp.js'
import RTKDownLoadAppIcon from '../icons/RtkDownloadApp.js'
import RTKMarketAnalyticsAppIcon from '../icons/RtkMarketAnalyticsApp.js'
import RTKUsersAppIcon from '../icons/RtkUsersApp.js'
import RTKCustomersAppIcon from '../icons/RtkCustomersApp.js'
import RTKThemeAppIcon from '../icons/RtkThemeApp.js'
import RTKSettingsAppIcon from '../icons/RtkSettingsApp.js'
import RTKHelpAppIcon from '../icons/RtkHelpApp.js'
import MedalIcon from '../icons/Medal.js'
import CoinsIcon from '../icons/ThreeStacksOfCoins.js'

/* STYLES */
import '../styles/rtkcolors.scss'
import '../styles/topmenubar.scss'
import Results from './Results';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Rhetorik
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#F8F8F8'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#343434',
    //maxHeight: '48px'
  },
//   appBarShift: {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
  menuButton: {
    //marginRight: 36,
    borderRadius: 0
  },
  menuButtonHidden: {
    display: '0',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 320,
  },
  appMenu: {
    display: 'flex',
    //marginLeft: theme.spacing(2),
    paddingTop: '3px',
    borderRadius: 0,
    '&:hover': {
       backgroundColor: '#17a4e0',
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  userProfileMenu: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    minWidth: 275,
    minHeight: 100,
    margin: '4px',
    padding: '4px',
  },
  divider: {
    height: 64,
    margin: 4,
    backgroundColor: "#515151",
  },
  MuiIconButtonRoot: {
      borderRadius: 0,
  }
//   appMenuActive: {
//     display: 'flex',
//     marginLeft: theme.spacing(2),
//     paddingTop: '3px',
//     backgroundColor: '#17a4e0',
//   },
}));



function Dashboard() {

    const classes = useStyles();
    // const [open, setOpen] = React.useState(true);
    // const [user, setUser] = React.useState<IUserProfile>();
    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<null | HTMLElement>(null)
    const [appMenuAnchorEl, setAppMenuAnchorEl] = React.useState<null | HTMLElement>(null)
    const [msgMenuAnchorEl, setMsgMenuAnchorEl] = React.useState<null | HTMLElement>(null)

    Auth.currentSession().then((sessionData: any) => {
      //store.dispatch(updateSession(sessionData))
      sessionStorage.setItem("accessToken", sessionData.idToken.jwtToken);
    });

    let userLogged;
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        userLogged = true;
      })
      .catch(err => {
        console.log("ERR");
        userLogged = false;
      });

    const awsSignOut = () => {
    Auth.signOut()
      .then(data => {
        console.log('AWS Sign Out data : ', data)
      })
      .catch(err => console.log(err))
  }


    /* UserMenu stuff */

    const isUserMenuOpen = Boolean(userMenuAnchorEl)

    const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setUserMenuAnchorEl(event.currentTarget)
      }
      const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null)
      }

    const userMenuId = 'primary-search-account-menu'
    const renderUserMenu = (
      <Popover
        className='UserMenu'
        id={userMenuId}
        open={isUserMenuOpen}
        anchorEl={userMenuAnchorEl}
        onClose={handleUserMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.userProfileMenu}>
          <List component='nav' aria-label='main mailbox folders'>
            <ListItem button onClick={handleUserMenuClose}>
              <ListItemText primary="Franck Persohn" />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary='Personal Settings' />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary='Request a Feature' />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary='Give Feedback' />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary='Rhetorik Help Center' />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary='Sign Out' onClick={awsSignOut} />
            </ListItem>
          </List>
        </div>
      </Popover>
    )
  

   /* MsgMenu stuff */

   const isMsgMenuOpen = Boolean(msgMenuAnchorEl)

   const handleMsgMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMsgMenuAnchorEl(event.currentTarget)
  }
  const handleMsgMenuClose = () => {
    setMsgMenuAnchorEl(null)
  }


   const ColorButton = withStyles((theme: Theme) => ({
    root: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FF9932',
      },
    },
    label: {
      textTransform: 'capitalize',
    },
  }))(Button)

  const msgMenuId = 'msg-menu'
  const renderMsgMenu = (
    <Popover
      className='UserMenu'
      id={msgMenuId}
      open={isMsgMenuOpen}
      anchorEl={msgMenuAnchorEl}
      onClose={handleMsgMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div className={classes.userProfileMenu}>
        <Typography>Messages</Typography>
        <Card className={classes.card}>
          <div>
            <SvgIcon
              component={RTKDownLoadAppIcon}
              viewBox='0 0 10240 10240'
              className='MsgIcon'
            />
            <span>Download</span>
          </div>
          <Typography>
            Your Telecom-Vetical-2019-12 data file is now ready for download.
          </Typography>
          <div className='right'>
            <ColorButton variant='outlined' size='small'>
              download
            </ColorButton>
          </div>
        </Card>
        <Card className={classes.card}>
          <div>
            <SvgIcon
              component={RTKCustomersAppIcon}
              viewBox='0 0 10240 10240'
              className='MsgIcon'
            />
            <span>Customers</span>
          </div>
          <Typography>
            Your license is about to expire. Contact customer support today.
          </Typography>
          <div className='right'>
            <ColorButton variant='outlined' size='small'>
              Renew
            </ColorButton>{' '}
            <ColorButton variant='outlined' size='small'>
              Contact
            </ColorButton>
          </div>
        </Card>
        <Card className={classes.card}>
          <div>
            <SvgIcon
              component={RTKUsersAppIcon}
              viewBox='0 0 10240 10240'
              className='MsgIcon'
            />
            <span>Users</span>
          </div>
          <Typography>Hi John and welcome to Rhetorik NetFinder.</Typography>
        </Card>
      </div>
    </Popover>
  )

/* AppMenu stuff */

const handleAppMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAppMenuAnchorEl(event.currentTarget)
  }

  const handleAppMenuClose = () => {
    setAppMenuAnchorEl(null)
  }
  const isAppMenuOpen = Boolean(appMenuAnchorEl)
  const id = isAppMenuOpen ? 'simple-popover' : undefined

  const renderAppMenu = (
    <Popover
      className='AppMenu'
      id={id}
      open={isAppMenuOpen}
      anchorEl={appMenuAnchorEl}
      onClose={handleAppMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Link href='/' className='AppMenuItem'>
        <SvgIcon
          component={RTKSearchAppIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Search</span>
      </Link>
      <div className='AppMenuItem'>
        <SvgIcon
          component={RTKSavedSearchIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Saved<br />Searches</span>
      </div>
      <Link href='/downloads' className='AppMenuItem'>
        <SvgIcon
          component={RTKDownLoadAppIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Download</span>
      </Link>
      <div className='AppMenuItem'>
        <SvgIcon
          component={RTKMarketAnalyticsAppIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Market<br />Analytics</span>
      </div>
      <div className='AppMenuItem'>
        <SvgIcon
          component={RTKUsersAppIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Users</span>
      </div>
      <div className='AppMenuItem'>
        <SvgIcon
          component={RTKCustomersAppIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Customers</span>
      </div>
      <div className='AppMenuItem'>
        <SvgIcon
          component={RTKThemeAppIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Theme</span>
      </div>
      <div className='AppMenuItem'>
        <SvgIcon
          component={RTKSettingsAppIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Settings</span>
      </div>
      <div className='AppMenuItem'>
        <SvgIcon
          component={RTKHelpAppIcon}
          viewBox='0 0 10240 10240'
          className='AppMenuIcon'
        />
        <span className='AppMenuCaption'>Help</span>
      </div>
    </Popover>
  )

  /* end AppMenu stuff */



  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div>
            <img
              src='../Rhetorik-New-Logo-White-01-1024x278.png'
              height='50px'
              alt='Rhetorik logo'
            />
          </div>

          <div className={classes.appMenu}>
            <IconButton
              aria-describedby={id}
              onClick={handleAppMenuOpen}
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'>
              <Typography variant='body1'>Search</Typography>
              <svg
                className='MuiSvgIcon-root MuiSvgIcon-fontSizeSmall'
                focusable='false'
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'></path>
              </svg>
            </IconButton>
            {renderAppMenu}
          </div>

        <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div className={classes.appMenu}>
              <Tooltip arrow TransitionComponent={Zoom} title='120 points earn today' placement='top'>
                <IconButton aria-label='show 4 new mails' color='inherit'>
                  <SvgIcon component={MedalIcon} viewBox='0 0 350 420' />
                  <div className='icon-button-text'>120</div>
                </IconButton>
              </Tooltip>
            </div>
            <Divider className={classes.divider} orientation="vertical" />
            <div className={classes.appMenu}>
            <Tooltip arrow TransitionComponent={Zoom} title='12 119 credits left' placement='top'>
              <IconButton
                aria-label='show 17 new notifications'
                color='inherit'>
                <SvgIcon component={CoinsIcon} viewBox='0 0 300 350' />
                <div className='icon-button-text'>12 119</div>
              </IconButton>
              </Tooltip>
            </div>
            <Divider className={classes.divider} orientation="vertical" />
            <div className={classes.appMenu}>
            <Tooltip arrow TransitionComponent={Zoom} title='3 new messages' placement='top'>
              <IconButton
                aria-describedby={id}
                onClick={handleMsgMenuOpen}
                aria-label='show 3 new messages'
                color='inherit'>
                <Badge badgeContent={3} color='secondary'>
                  <MessagesIcon />
                </Badge>
              </IconButton>
              </Tooltip>
              {renderMsgMenu}
            </div>
            <Divider className={classes.divider} orientation="vertical" />
            <div className={classes.appMenu}>
            <Tooltip arrow TransitionComponent={Zoom} title='Franck Persohn' placement='top'>
              <IconButton
                aria-describedby={id}
                onClick={handleUserMenuOpen}
                className={classes.menuButton}
                color='inherit'
                aria-label='open drawer'>
                <AccountCircle />
              </IconButton>
              </Tooltip>
            </div>
          </div>


        </Toolbar>
      </AppBar>
      {renderUserMenu}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
         <Omnibox />
        </Container>
        <Container maxWidth="lg">
            <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className={classes.paper}>
                <Results />
              </Paper>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
                <Paper className={classes.paper}>
                <Chart title='Distribution by Job Function' barColor='#17a4e0' />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <Paper className={classes.paper}>
                <Chart title='Distribution by Vertical Market' barColor='#5B463B' />
              </Paper>
            </Grid>
          </Grid>
          <Footer />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

//export default withAuthenticator(Dashboard);
export default Dashboard;