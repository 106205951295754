import React from 'react'

function SvgRtkCustomersApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#3E9BD5'
        d='M4480 8960l394-2166-207-207c-124-125-187-276-187-452v-127c206 47 420 72 640 72s434-25 640-72v127c0 176-63 327-187 452l-207 207 394 2166-640 640-640-640z'
      />
      <path
        fill='#252525'
        d='M8320 8470c0 716-1502 1049-2734 1117l400-401c77-76 108-177 89-283l-364-2001 88-88c184-185 281-418 281-679v-52-74c0-31-4-61-12-89 208-73 406-168 589-284 1082 807 1663 1074 1663 2834zM4654 9587c-1232-68-2734-401-2734-1116 0-1760 582-2029 1663-2835 183 116 381 212 589 284-8 28-12 58-12 89v126c0 261 97 494 281 679l88 88-364 2001c-19 106 12 207 89 283l400 401zM5120 640c1414 0 2560 1146 2560 2560S6534 5760 5120 5760 2560 4614 2560 3200 3706 640 5120 640z'
      />
    </svg>
  )
}

export default SvgRtkCustomersApp
