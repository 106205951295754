export enum Environment {
  LOCALHOST = 'localhost',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export function getCurrentEnvironment(): Environment {
  const domain: string = window.location.hostname.toLowerCase()

  if (domain.includes('localhost')) {
    return Environment.LOCALHOST
  } else if (domain.includes('development.')) {
    return Environment.DEVELOPMENT
  } else if (domain.includes('staging.')) {
    return Environment.STAGING
  } else {
    return Environment.PRODUCTION
  }
}

function getAPIDomain(): string {
  const domain: string = window.location.hostname.toLowerCase()

  if (domain.includes('localhost')) {
    return 'development.api.rhetorik.com'
  } else if (domain.includes('development.')) {
    return 'development.api.rhetorik.com'
  } else if (domain.includes('staging.')) {
    return 'development.api.rhetorik.com'
  } else {
    return 'development.api.rhetorik.com'
  }
}

export enum APICalls {
  SEARCH = '/proxy-service/ROOT/search/_search',
  LOG = '/platform-service/statistics',
  REACTIVESEARCH = '/proxy-service/ROOT/search/',
  USERPROFILE = '/platform-service/user-profile/'
}

export function getAPIURL(apiCall: APICalls): string {
  return 'https://' + getAPIDomain() + apiCall
}
