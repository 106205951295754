import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Title from './Title'
import _ from 'lodash'

interface CountryType {
  name: string;
}

const useStyles = makeStyles((theme) => ({
  resultContainer: { 
    display: "flex",
    padding: "10px" },
  resultLeftContainer: {display: "flex", flexDirection: "column", justifyContent: "flex-end", minHeight: "130px", width: "8px", backgroundColor: "#E0E0E0", margin: "0 4px", borderRadius: "2px"},
  resultSlider: {height: "50px", width: "100%", borderRadius: "2px" },
  resultRightContainer: { padding: "10px"},
  resultNumber: { fontSize: "38px", fontWeight: "bold"},
  resultText: { fontSize: "18px", fontWeight: "bold", color: "#232323"},
  color1: { color: "#E19932"},
  color2: { color: "#6F584B"},
  color3: { color: "#64A7C4"},
  color4: { color: "#A76771"},
  color5: { color: "#97BD9D"},
  backgroundColor1: { backgroundColor: "#E19932"},
  backgroundColor2: { backgroundColor: "#6F584B"},
  backgroundColor3: { backgroundColor: "#64A7C4"},
  backgroundColor4: { backgroundColor: "#A76771"},
  backgroundColor5: { backgroundColor: "#97BD9D"},

}));

export default function Results() {


  const classes = useStyles();

  return (
          <React.Fragment>
            <Title>Results</Title>
            {/* <Grid container direction="row" justify="flex-end" alignItems="flex-start">
              <Button variant="outlined">Create Mission</Button>
              <Button variant="outlined">Save</Button>
              <Button variant="contained" disableElevation>Export</Button>
            </Grid> */}
          <Grid container alignItems="center">
            <Grid item xs>
            <div className={classes.resultContainer}>
              <div className={classes.resultLeftContainer}>
                <div className={`${classes.resultSlider} ${classes.backgroundColor1}`}></div>
              </div>
              <div className={classes.resultRightContainer}>
                <div className={`${classes.resultNumber} ${classes.color1}`}>4 675</div>
                <div className={classes.resultText}>Companies</div>
              </div>
            </div>
            </Grid>
            <Grid item xs>
            <div className={classes.resultContainer}>
              <div className={classes.resultLeftContainer}>
              <div className={`${classes.resultSlider} ${classes.backgroundColor2}`}></div>
              </div>
              <div className={classes.resultRightContainer}>
              <div className={`${classes.resultNumber} ${classes.color2}`}>4 675</div>
                <div className={classes.resultText}>Offices</div>
              </div>
            </div>
            </Grid>
            <Grid item xs>
            <div className={classes.resultContainer}>
              <div className={classes.resultLeftContainer}>
              <div className={`${classes.resultSlider} ${classes.backgroundColor3}`}></div>
              </div>
              <div className={classes.resultRightContainer}>
              <div className={`${classes.resultNumber} ${classes.color3}`}>4 675</div>
                <div className={classes.resultText}>Key people</div>
              </div>
            </div>
            </Grid>
            <Grid item xs>
            <div className={classes.resultContainer}>
              <div className={classes.resultLeftContainer}>
              <div className={`${classes.resultSlider} ${classes.backgroundColor4}`}></div>
              </div>
              <div className={classes.resultRightContainer}>
              <div className={`${classes.resultNumber} ${classes.color4}`}>4 675</div>
                <div className={classes.resultText}>Products and<br />Services used</div>
              </div>
            </div>
            </Grid>
            <Grid item xs>
            <div className={classes.resultContainer}>
              <div className={classes.resultLeftContainer}>
              <div className={`${classes.resultSlider} ${classes.backgroundColor5}`}></div>
              </div>
              <div className={classes.resultRightContainer}>
              <div className={`${classes.resultNumber} ${classes.color5}`}>4 675</div>
                <div className={classes.resultText}>Key people<br />with Email</div>
              </div>
            </div>
            </Grid>
          </Grid>

          </React.Fragment>
            
              );
}