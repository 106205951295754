import React from 'react'

function SvgRtkSearchApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#3E9BD5'
        d='M5986 6814l828-828c124-124 328-124 452 0l2428 2428c124 124 124 328 0 452l-828 828c-124 124-328 124-452 0L5986 7266c-124-124-124-328 0-452z'
      />
      <path
        fill='#252525'
        d='M6082 5442l412 412-640 640-412-412c-534 401-1197 638-1916 638-1764 0-3194-1430-3194-3194S1762 332 3526 332s3194 1430 3194 3194c0 719-237 1382-638 1916zM3526 971C2115 971 971 2115 971 3526s1144 2555 2555 2555 2555-1144 2555-2555S4937 971 3526 971z'
      />
    </svg>
  )
}

export default SvgRtkSearchApp
