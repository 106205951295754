import { Environment, getCurrentEnvironment } from './api.service'

interface AuthenticationConfiguration {
  Auth: {
    region: string
    userPoolId: string
    userPoolWebClientId: string
    mandatorySignIn: boolean
  }
}

// Staging pool: eu-west-1_sTy2rUMGk
// Staging PoolWebClientId: 675ohveg1uj9urak811kajn5to

// Prod pool: eu-west-1_LQcHrJDL8
// Prod PoolWebClientId: 7m0gp8t9t0d44kavf3b6mjpuvk

// Dev (and default) pool: eu-west-1_eMcJ4rRm3
// Dev (et default) PoolWebClientId: 704ge8jni51bthldsk1qdmq5lg

export function getAuthenticationConfiguration(): AuthenticationConfiguration {
  switch (getCurrentEnvironment()) {
    case Environment.STAGING: {
      return {
        Auth: {
          region: 'eu-west-1',
          userPoolId: 'eu-west-1_sTy2rUMGk',
          userPoolWebClientId: '675ohveg1uj9urak811kajn5to',
          mandatorySignIn: true,
        },
      }
    }
    case Environment.PRODUCTION: {
      return {
        Auth: {
          region: 'eu-west-1',
          userPoolId: 'eu-west-1_LQcHrJDL8',
          userPoolWebClientId: '7m0gp8t9t0d44kavf3b6mjpuvk',
          mandatorySignIn: true,
        },
      }
    }
    case Environment.LOCALHOST:
    case Environment.DEVELOPMENT:
    default: {
      return {
        Auth: {
          region: 'eu-west-1',
          userPoolId: 'eu-west-1_eMcJ4rRm3',
          userPoolWebClientId: '704ge8jni51bthldsk1qdmq5lg',
          mandatorySignIn: true,
        },
      }
    }
  }
}
