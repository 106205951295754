import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import AppWithAuth from './appWithAuth'
import Amplify from 'aws-amplify'
import { getAuthenticationConfiguration } from './services/configuration.service'
Amplify.configure(getAuthenticationConfiguration())


ReactDOM.render(<AppWithAuth />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
