import React from 'react'

function SvgRtkSettingsApp(props) {
  return (
    <svg
      width={1024}
      height={1024}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      clipRule='evenodd'
      viewBox='0 0 10240 10240'
      {...props}
    >
      <path
        fill='#252525'
        d='M7140 2420c110 82 214 170 313 265 42 40 97 54 153 39l874-238c135-37 272 15 350 131 127 191 234 377 334 584 61 126 35 271-66 368l-649 631c-42 40-58 95-45 151 32 134 55 269 69 406 6 58 40 104 92 128l822 380c127 59 199 186 184 325-25 228-64 440-120 662-35 136-148 230-287 240l-904 66c-58 4-105 35-131 87-62 122-132 240-208 354-32 48-37 105-12 157l385 818c60 127 33 271-69 368-166 158-332 296-518 430-114 81-261 81-374-1l-734-531c-47-33-104-40-157-17-125 53-253 99-383 136-56 16-96 57-111 113l-230 874c-36 136-150 230-290 238-230 14-445 13-675-5-139-10-252-105-286-240l-221-879c-14-56-53-97-109-114-128-39-254-86-377-141-53-23-110-17-157 16l-739 522c-115 81-262 79-375-4-186-137-350-277-515-439-100-98-126-242-64-368l395-816c25-52 21-109-10-157-73-112-139-228-198-348-26-51-73-83-130-88l-904-76c-140-11-251-107-284-243-55-225-91-438-113-668-14-139 60-266 187-323l827-371c53-23 87-69 94-127 15-132 39-264 71-394 13-56-2-110-43-151l-644-639c-99-99-123-243-61-369 102-207 211-393 342-584 79-116 216-166 351-127l871 247c56 16 111 3 154-37 98-92 201-178 309-258 46-34 70-86 65-143l-83-904c-13-139 62-266 190-322 210-93 413-166 635-228 135-38 273 12 352 128l508 750c32 47 83 73 141 70 136-8 272-7 407 1 58 4 109-21 142-68l517-745c80-115 219-164 353-125 220 64 422 139 632 233 127 58 201 185 186 325l-92 900c-6 58 17 110 63 145zm-2020 780c-1060 0-1920 860-1920 1920s860 1920 1920 1920 1920-860 1920-1920-860-1920-1920-1920z'
      />
    </svg>
  )
}

export default SvgRtkSettingsApp
